@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&family=Overpass&family=Roboto+Condensed:ital,wght@1,700&family=Signika&display=swap');

body {
  margin: 0;
  font-family: 'Domine', serif;
  font-family: 'Overpass', sans-serif;
  font-family: 'Roboto Condensed', sans-serif;
  font-family: 'Signika', sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
